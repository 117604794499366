import { useRef, useState } from "react";
import Header from "../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";

const EditClient = () => {
  const location = useLocation();
  const data = location.state;
  const [c_name, setc_name] = useState(data?.c_name || "");
  const [fullAddress, setfullAddress] = useState(data?.fullAddress || "");
  const [mobile, setmobile] = useState(data?.mobile || "");
  const [gstin, setgstin] = useState(data?.gstin || "");
  const [pincode, setpincode] = useState(data?.pincode || "");
  const [username, setusername] = useState(data?.username || "");
  const [password, setpassword] = useState(data?.password || "");

  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();

  //   _id: "6527e42d8ce0c74a19573025"
  //   ​
  //   address1: "DAMOH"
  //   ​
  //   address2: "DAMOH"
  //   ​
  //   c_name: "GLOBE ENGINEERING AND CONSTURCTION"
  //   ​
  //   city: "Damoh"
  //   ​
  //   email: "API_GLOBE"
  //   ​
  //   email1: ""
  //   ​
  //   fullAddress: "LAKSHMAN MANDAL, INDRA MOHAN NAGAR, DAMOH, Damoh, Madhya Pradesh"
  //   ​
  //   gstin: "23AKFPM1610B1Z1"
  //   ​
  //   mobile: "9977010002"
  //   ​
  //   password: "Dewas@123"
  //   ​
  //   pincode: "470661"
  //   ​
  //   role: "user"
  //   ​
  //   shopName: "LAKSHMAN MANDAL"
  //   ​
  //   state: "Madhya Pradesh"
  //   ​
  //   state1: "23"
  //   ​
  //   statecode: "Madhya Pradesh"
  //   ​
  //   subcription: 1
  //   ​
  //   subcriptionAmount: "0"
  //   ​
  //   username: "API_GLOBE"

  const EditClient = async () => {
    if (isSubmit) return;
    setisSubmit(true);

    try {
      const url = "/subcription/edit";
      const response = await axios.post(
        url,
        {
          c_name,
          fullAddress,
          pincode,
          mobile,
          gstin,
          username,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/subcription/user/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="Edit Client" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit Client</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">Company Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Company Name"
                          value={c_name}
                          onChange={(e) => setc_name(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username">Address: </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="text"
                          placeholder="Enter Parent Name"
                          value={fullAddress}
                          onChange={(e) => setfullAddress(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="mobile">Mobile:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="mobile"
                          value={mobile}
                          placeholder="Enter Mobile "
                          onChange={(e) => setmobile(e.target.value)}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="whom">GSTIN:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          readOnly
                          id="customerAge"
                          placeholder="Enter Customer Age"
                          value={gstin}
                          onChange={(e) => setgstin(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="whom">Pincode:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="monthly Income"
                          placeholder="Enter Monthly Income"
                          value={pincode}
                          onChange={(e) => setpincode(e.target.value)}
                        />
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />

                      <div className="col-md-3">
                        <label htmlFor="name">Username:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Username"
                          value={username}
                          onChange={(e) => setusername(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">Password:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={EditClient}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EditClient;
