import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import Loader from "../components/loader";
import StatusLoader from "../components/statusLoader";
import swal from "sweetalert";
import { useReactToPrint } from "react-to-print";
import { IMAGE_URL } from "../config/config";
import styled from "styled-components";

const Wrapper = styled.div`
  #printableArea {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    color: #333;
    line-height: 25px;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    a {
      color: #333;
      text-decoration: none;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }

    i:before {
      margin-left: 0 !important;
    }

    a:hover,
    a:focus {
      text-decoration: none;
      outline: none;
      color: #e12454;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      font-family: "Roboto", sans-serif;
      line-height: 1.1;
      font-weight: 500;
      color: #222222;
      margin: 0;
      padding: 0;
    }

    h1 a,
    h2 a,
    h3 a,
    h4 a,
    h5 a,
    h6 a {
      color: #222222;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }

    h1,
    .h1 {
      font-size: 65px;
    }

    h2,
    .h2 {
      font-size: 55px;
    }

    h3,
    .h3 {
      font-size: 45px;
    }

    h4,
    .h4 {
      font-size: 36px;
    }

    h5,
    .h5 {
      font-size: 30px;
    }

    h6,
    .h6 {
      font-size: 28px;
    }

    h1 a:hover,
    h2 a:hover,
    h3 a:hover,
    h4 a:hover,
    h5 a:hover,
    h6 a {
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }

    p {
      margin: 0;
    }
    .pad {
      padding: 0;
    }
    .mar {
      margin: 0;
    }
    input,
    select,
    button,
    textarea {
      outline: none;
    }

    input:focus,
    select:focus,
    button:focus,
    textarea:focus {
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .custom-container {
      max-width: 1600px;
    }
    .pl-left {
      padding-left: 5px;
    }
    .right-side {
      text-align: right;
    }
    /*================css start============*/

    .loan-application-main-wrapper {
      width: 100%;
      margin: 0 auto;
      padding: 20px 11px;
      border: 1px solid #111;
    }
    .loan-application-main-wrapper .invoice-title {
      width: 100%;
      float: left;
      text-align: center;
      border-bottom: 1px solid #111;
      padding-bottom: 20px;
    }
    .loan-application-main-wrapper .invoice-title .title-heading {
      font-size: 40px;
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
    }
    .loan-application-main-wrapper .invoice-title p {
      font-size: 14px;
      color: #111;
      font-weight: 800;
      line-height: 22px;
    }
    .loan-application-main-wrapper .invoice-title span {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 800;
      color: #111;
    }
    .di-invoice-main-wrapper {
      width: 100%;
      float: left;
      border-bottom: 1px solid #111;
    }
    .di-invoice-main-wrapper .invoice-left-side {
      width: 100%;
      float: left;
      position: relative;
    }
    .di-invoice-main-wrapper .invoice-left-side:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      // width: 1px;
      height: 100%;
      background: #111;
    }
    .di-invoice-main-wrapper .aftr-remove:after {
      display: none;
    }
    .di-invoice-main-wrapper .invoice-left-side ul {
      padding: 10px 10px;
    }
    .invoice-left-side.first-invoce-tb ul {
      padding: 10px 22px !important;
    }
    .di-invoice-main-wrapper .invoice-left-side ul li {
      width: 100%;
      color: #111;
      font-size: 16px;
      line-height: 22px;
    }
    .di-invoice-main-wrapper .invoice-left-side ul li span {
      display: inline-block;
      padding-left: 8px;
    }

    .di-invoice-main-wrapper .invoice-left-side .invoice-buyer-title {
      border-bottom: 1px solid #111;
      padding: 5px 0px;
    }
    .invoice-left-side.first-invoce-tb ul li {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      padding: 3px;
    }
    .di-invoice-main-wrapper .invoice-left-side .invoice-buyer-title p {
      padding-left: 10px;
      font-weight: 600;
      font-size: 16px;
      color: #111;
    }
    .table-invoice-details {
      width: 100%;
      float: left;
    }
    .table-invoice-details table thead tr th {
      background: #ccc;

      -webkit-print-color-adjust: exact;
      font-size: 14px;
      line-height: 22px;
      width: 211px;
      text-align: center;
    }
    .table-invoice-details table thead tr .w-1 {
      width: 80px;
    }
    .table-invoice-details table thead tr .w-2 {
      width: 114px;
    }
    .table-invoice-details table thead tr .w-3 {
      width: 151px;
    }
    .table-invoice-details table thead tr .w-4 {
      width: 90px;
    }

    .pad-left {
      padding-left: 0;
    }
    .pad-right {
      padding-right: 0;
    }
    .table-invoice-details table {
      width: 100%;
    }

    .table-invoice-details table tbody tr td {
      padding: 20px 10px !important;
      font-size: 14px;
      font-weight: 600;
    }
    .table-invoice-details table tbody tr .remove-line {
      font-weight: 600;
      font-style: italic;
      font-size: 15px;
    }
    .table-invoice-details .bank-title {
      font-size: 18px;

      font-weight: 700;
      width: 100%;
    }
    .table-invoice-details .terms-text .bank-title {
      font-size: 18px;
      text-align: center;
      font-weight: 700;
      width: 100%;
    }

    .table-invoice-details table tbody tr td .terms-text .bank-detals-box {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 15px;
    }
    .table-invoice-details .terms-text .bank-detals-box .bank-label {
      width: 115px;
    }
    .table-invoice-details .terms-text .bank-detals-box .bank-label p {
      font-weight: 600;
      font-size: 16px;
      color: #111;
    }
    .table-invoice-details .terms-text .bank-detals-box .bank-fill p {
      font-weight: 600;
      font-size: 16px;
      color: #111;
    }
    .table-invoice-details .demo ul li + li {
      margin-top: 65px;
    }
    .table_box .demo {
      text-align: center;
    }
    .table_box .tableBox {
      border: 1px solid #000;
      border-top: 0;
    }
    .table_box .tableBox .terms-text {
      padding: 20px 10px;
    }
    .table_box .tableBox .terms-text ul li {
      font-weight: 600;
    }
    .table_box .tableBox .col-6.pl-left {
      border-left: 1px solid #000;
      padding: 0;
      padding-left: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
    .table_box .tableBox .col-6.pl-left.col-8 {
      position: static;
      justify-content: center;
    }
    .table_box .tableBox .col-6.pl-left.col-8::after {
      display: none;
    }

    .table_box .tableBox .col-6.pl-left::after {
      content: "";
      position: absolute;
      top: 0;
      right: 193px;
      width: 1px;
      background: #000;
      height: 100%;
    }
    .table_box .tableBox.count {
      text-align: right;
      padding: 20px 10px;
      font-weight: 600;
      font-style: italic;
      font-size: 15px;
    }
    .bank-detals-box {
      display: flex;
      margin-top: 20px;
    }
    tbody td {
      font-size: 14px;
      line-height: 22px !important;
      padding: 20px !important;
    }
    .table-bordered > :not(caption) > * > * {
      border: solid 1px #111111;
    }

    @media (max-width: 991px) {
      .container {
        max-width: 990px;
      }
    }

    @media (max-width: 380px) {
      .loan-application-main-wrapper .invoice-title .title-heading {
        font-size: 30px;
      }
    }

    .table-invoice-details .demo ul li + li {
      margin-top: 40px;
    }
    .loan-application-main-wrapper .invoice-title .title-heading {
      font-size: 25px !important;
    }
    .col-4 {
      padding: 0px !important;
    }

    .table > :not(caption) > * > * {
      line-height: 19px;
    }

    .table li {
      line-height: 18px;
    }
    .invoice-title {
      padding-bottom: 2px !important;
    }

    .invoice-left-side.first-invoce-tb ul {
      padding: 2px 22px !important;
    }
    .bank-detals-box {
      display: flex;
      margin-top: 2px;
    }
    .tableBox.row.m-0,
    .tableBox.row.m-0 p,
    .loan-application-main-wrapper .invoice-title p {
      font-size: 12px !important;
      line-height: 15px;
    }
    td {
      word-break: break-all;
    }
    .table-invoice-details table tbody tr td {
      padding: 0px 5px !important;
      font-size: 12px;
    }
    .table-invoice-details table thead tr th {
      line-height: 0.9;
      font-size: 11px;
      padding: 3px 2px !important;
    }

    .table {
      margin-bottom: 0px;
      position: relative;
      background: white;
    }

    .loan-application-main-wrapper {
      padding: 0px px !important;
    }
    .table_box .tableBox .terms-text {
      padding: 4px 10px;
    }

    .table_box th {
      padding: 0px 0px !important;
    }

    .di-invoice-main-wrapper li {
      font-size: 12px !important;
      line-height: 18px !important;
    }
  }

  @media print {
    @page {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      margin: 0;
      position: absolute;
    }
    body {
      padding-top: 20px;
      padding-bottom: 20px;
      font-family: "Lato", sans-serif;
      font-size: 16px;
      color: #333;
      line-height: 25px;
      width: 100%;
      height: 100%;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }
  }

  @media (max-width: 991px) {
    .container {
      max-width: 990px;
    }
  }

  @media (max-width: 380px) {
    .loan-application-main-wrapper .invoice-title .title-heading {
      font-size: 30px;
    }
  }
`;

const InvoicePrint = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [statusLoding, setstatusLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const uniqeID = location?.state?.uniqeId;
  console.log(uniqeID);
  const [invoiceData, setInvoiceData] = useState();
  const { auth } = useAuth();
  console.log(auth?.user);
  useEffect(() => {
    const fetchInvoice = async () => {
      setstatusLoading(true);
      try {
        const response = await axios.post(
          "/invoice/fetch-invoice",
          {
            uniqeId: uniqeID,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setInvoiceData(response.data);
        setstatusLoading(false);
      } catch (err) {
        swal("Oops!", err?.message, "error");
        console.log(err);
      }
    };
    fetchInvoice();
  }, []);

  const data = invoiceData?.invoice[0];
  const client = invoiceData?.client[0];
  const itemList = invoiceData?.itemList;
  console.log(itemList);

  const imgStyle =
    data?.ewb_dt != "null" && data?.ewb_no != "null"
      ? {
          height: "200px",
          width: "200px",
          marginTop: "-160px",
        }
      : {
          height: "200px",
          width: "200px",
          marginTop: "auto",
        };

  /**
   *  Generate E-Invoice Function
   */
  console.log(data?.irn);
  const generateEInvoice = async () => {
    console.log("Clicked");
    setIsLoading(true);

    try {
      const response = await axios.post(
        "/eInvoice/generate-eInvoice",
        {
          uniqeId: uniqeID,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      console.log(response.data.Errors);
      if (response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/eInvoicePrint", {
          state: {
            uniqeId: uniqeID,
          },
        });
      } else {
        toast.error("Einvoice Not Generated", {
          position: toast.POSITION.TOP_RIGHT,
        });
        toast.error("Please Go Back And Write Valid Parameter", {
          position: toast.POSITION.TOP_RIGHT,
        });

        navigate("/einvoiceProcess", {
          state: {
            uniqeId: uniqeID,
            Errors: response.data.Errors,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  /**
   * E-Invoice Generate Function End
   */

  /**
   * Code For Go Reverce Page
   */

  const handleGoBack = () => {
    window.history.back();
  };

  /**
   * End
   */
  /**
   * Print Logic Start
   */
  var componentRef = useRef(null);
  const handlePrint = () => {
    const targetElement = componentRef.current;
    if (targetElement) {
      window.print();
    }
  };
  /**
   * Print Logic End
   */
  /**
   * Convert Date Formate Fuction
   */

  const DateForamate = (inputDateString) => {
    if (!inputDateString) {
      return "  ";
    }
    const date = new Date(inputDateString);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    return date.toLocaleDateString("en-IN", options).replace("/", "/");
  };

  /**
   * Date Formate Function End
   */
  return (
    <>
      {statusLoding ? (
        <StatusLoader isLoading={statusLoding} />
      ) : (
        <>
          <Wrapper>
            <div id="printable">
              <div className="container" id="printableArea" ref={componentRef}>
                <div className="loan-application-main-wrapper">
                  <div className>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mar pad layout-grid">
                        <div className="invoice-title">
                          <span style={{ fontSize: "14px" }}>
                            {data?.irn ? "Tax E-Invoice" : "Tax Invoice"}
                          </span>
                          <h2
                            className="title-heading"
                            style={{ marginBottom: "0" }}
                          >
                            {auth?.user?.c_name}
                          </h2>
                          <p style={{ marginBottom: "0" }}>
                            {auth?.user?.fullAddress},{" , "}
                            {auth?.user?.pincode}
                          </p>
                          {/* <p style={{ marginBottom: "0" }}>
                            Email: abc@gmail.com | Web: www.syncinfo.org
                          </p>
                          <p style={{ marginBottom: "0" }}>
                            Mob.: 9000000000, Resi. 9000000000
                          </p> */}
                          <p style={{ marginBottom: "0" }}>
                            GSTIN : {auth?.user?.gstin}
                          </p>
                        </div>
                        {/*  */}
                        <div className="di-invoice-main-wrapper">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-6 mar pad">
                              <div className="invoice-left-side first-invoce-tb">
                                <ul>
                                  <li>
                                    Invoice No :{" "}
                                    <span>{data?.invoiceNumber}</span>
                                  </li>
                                  <li>
                                    Invoice Date :{" "}
                                    <span>
                                      {data?.invoiceDate}
                                      {console.log(data?.invoiceDate)}

                                    </span>{" "}
                                  </li>
                                  <li>
                                    Document Type : {data?.invoiceType || "INV"}
                                    <span>{" "}
                                    </span>{" "}
                                  </li>
                                  <li>
                                    Reserve Charge : {data?.rc || "No"}{" "}
                                  </li>
                                  <li>
                                    State :{" "}
                                    <span>
                                      {auth?.user?.state || "Madhya Pradesh"}
                                    </span>{" "}
                                  </li>
                                  <li>
                                    State Code :{" "}
                                    <span>{auth?.user?.state1}</span>{" "}
                                  </li>
                                  {/* <li>
                                    IRN : <span>{data?.irn}</span>{" "}
                                  </li>
                                  <li>
                                    Ack Number : <span>{data?.ackNo}</span>{" "}
                                  </li>
                                  <li>
                                    Ack Date : <span>{data?.ackDate}</span>{" "}
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-6 col-6 mar pad">
                              <div className="invoice-left-side aftr-remove first-invoce-tb">
                                <ul>
                                  {data?.ewb_dt != "null" &&
                                  data?.ewb_no != "null" ? (
                                    <>
                                      <li>
                                        Transport Name :{"  "}
                                        <span> {data?.transportName}</span>
                                      </li>
                                      <li>
                                        Veh No : {"  "}
                                        <span>{data?.vehNumber} </span>
                                      </li>
                                      <li>
                                        LR No :{"  "}{" "}
                                        <span>{data?.lrNumber} </span>
                                      </li>
                                      <li>
                                        E-Way Bill No :{"  "}
                                        <span>{data?.ewb_no}</span>
                                      </li>
                                      <li>E-Way Bill Date : {data?.ewb_dt} </li>
                                      <li>
                                        E-Way Bill Valid Date :{" "}
                                        <span>{data?.ewb_valid_till}</span>
                                      </li>
                                      <li>
                                        Place of Supply :{" "}
                                        <span>{data?.shippingState}</span>
                                      </li>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <li
                                    style={{
                                      marginLeft: "55%",
                                    }}
                                  >
                                    {data?.imgName ? (
                                      <img
                                        style={imgStyle}
                                        src={IMAGE_URL + data?.imgName}
                                        alt="QR Image"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div
                          className="di-invoice-main-wrapper"
                          style={{ fontSize: "14px" }}
                        >
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-6 pad-right">
                              <div className="invoice-left-side">
                                <div className="invoice-buyer-title">
                                  <p>Supplier Details</p>
                                </div>

                                <div style={{ borderLeft: "6px solid green" }}>
                                  {" "}
                                </div>
                                <ul
                                  style={{
                                    width: "100%",
                                    paddingRight: "0px",
                                    minHeight: "160px",
                                    float: "left",
                                    borderRight: "1px solid",
                                    fontWeight: 600,
                                  }}
                                >
                                  <li>
                                    {" "}
                                    Name : {"   "} {auth?.user?.c_name}
                                  </li>
                                  <li>
                                    Address :{"  "}
                                    {auth?.user?.fullAddress}
                                  </li>
                                  <li>
                                    GSTIN :{"  "}
                                    {auth?.user?.gstin}
                                  </li>
                                  <li>
                                    State :{"  "}
                                    {auth?.user?.state}
                                  </li>
                                  <li>
                                    State Code:{"  "}
                                    {auth?.user?.state1}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-6 pad-left">
                              <div className="invoice-left-side aftr-remove">
                                <div className="invoice-buyer-title">
                                  <p>Recipient Details</p>
                                </div>

                                <ul
                                  style={{
                                    width: "100%",
                                    paddingRight: "0px",
                                    minHeight: "160px",
                                    float: "left",
                                    borderRight: "1px solid",
                                    fontWeight: 600,
                                  }}
                                >
                                  <li>
                                    {" "}
                                    <b>
                                      {" "}
                                      Name : {"  "}
                                      {client?.partyname}{" "}
                                    </b>
                                  </li>
                                  <li>
                                    Address :{"  "}
                                    {client?.fullAddress}
                                    {"  "}
                                    {client?.pincode}
                                  </li>
                                  <li>
                                    GSTIN :{"  "}
                                    {client?.gstin}
                                  </li>
                                  <li>
                                    State :{"  "}
                                    {client?.state}{" "}
                                  </li>
                                  <li>
                                    State Code:{"  "}
                                    {client?.gstin.substring(0, 2)}{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="di-invoice-main-wrapper"
                          style={{ fontSize: "14px" }}
                        >
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-6 pad-right">
                              <div className="invoice-left-side">
                                <div className="invoice-buyer-title">
                                  <p>Loading From</p>
                                </div>

                                <div style={{ borderLeft: "6px solid green" }}>
                                  {" "}
                                </div>
                                <ul
                                  style={{
                                    width: "100%",
                                    paddingRight: "0px",
                                    minHeight: "160px",
                                    float: "left",
                                    borderRight: "1px solid",
                                    fontWeight: 600,
                                  }}
                                >
                                  <li>
                                    {" "}
                                    <b>
                                      {" "}
                                      Name :{"  "}
                                      {data?.loadingName}{" "}
                                    </b>
                                  </li>
                                  <li>
                                    Address : {"  "} {data?.loadingAddress}
                                    {"  "}
                                    {data?.loadingPincode}
                                  </li>
                                  <li>
                                    GSTIN :{"  "}
                                    {data?.loadingGSTIN}
                                  </li>
                                  <li style={{ fontSize: "14px" }}>
                                    State :{"  "}
                                    {data?.loadingState}
                                  </li>
                                  <li style={{ fontSize: "14px" }}>
                                    State Code:{"  "}
                                    {data?.loadingGSTIN.substring(0, 2)}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-6 pad-left">
                              <div className="invoice-left-side aftr-remove">
                                <div className="invoice-buyer-title">
                                  <p>Shipped To</p>
                                </div>

                                <ul
                                  style={{
                                    width: "100%",
                                    paddingRight: "0px",
                                    minHeight: "160px",
                                    float: "left",
                                    borderRight: "1px solid",
                                    fontWeight: 600,
                                  }}
                                >
                                  <li>
                                    {" "}
                                    <b>
                                      {" "}
                                      Name : {"  "}
                                      {data?.shippingName}{" "}
                                    </b>
                                  </li>
                                  <li>
                                    Address :{"  "}
                                    {data?.shippingAddress}
                                    {"  "} {data?.shippingPincode}
                                  </li>
                                  <li>
                                    GSTIN :{"  "}
                                    {data?.shippingGSTIN}
                                  </li>
                                  <li style={{ fontSize: "14px" }}>
                                    State :{"  "}
                                    {data?.shippingState}
                                  </li>
                                  <li style={{ fontSize: "14px" }}>
                                    State Code:{"  "}
                                    {data?.shippingGSTIN.substring(0, 2)}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        <div className="table-invoice-details">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                              {/*<div class="table table-responsive"> */}
                              <div className="table">
                                <table className="table table-bordered m-0">
                                  {parseFloat(data?.igst) !== 0 ? (
                                    <thead>
                                      <tr>
                                        <th className="w-8">Sr No.</th>
                                        <th className="w-18">HSN/SAC</th>
                                        <th style={{}}>Description of Goods</th>
                                        <th className="w-32">Quantity UQC</th>
                                        <th className="w-42">Rate</th>
                                        <th className="w-22">Taxable Value</th>
                                        <th style={{ width: "150px" }}>
                                          IGST Amount
                                        </th>
                                        <th style={{ width: "50px" }}>
                                          IGST Rate
                                        </th>
                                        <th className="w-70">Total</th>
                                      </tr>
                                    </thead>
                                  ) : (
                                    <thead>
                                      <tr>
                                        <th className="w-8">Sr No.</th>
                                        <th className="w-18">HSN/SAC</th>
                                        <th style={{}}>Description of Goods</th>
                                        <th className="w-32">Quantity UQC</th>
                                        <th className="w-42">Rate</th>
                                        <th className="w-22">Taxable Value</th>
                                        <th style={{ width: "150px" }}>
                                          CGST Amount
                                        </th>
                                        <th style={{ width: "80px" }}>
                                          CGST Rate
                                        </th>
                                        <th style={{ width: "150px" }}>
                                          SGST Amount
                                        </th>
                                        <th style={{ width: "80px" }}>
                                          SGST Rate
                                        </th>
                                        <th className="w-70">Total</th>
                                      </tr>
                                    </thead>
                                  )}
                                  {parseFloat(data?.igst) !== 0 ? (
                                    <tbody>
                                      {itemList?.map((item, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{item.hsnCode}</td>
                                          <td>{item?.itemName}</td>
                                          <td>
                                            {item?.quantity} {item?.unit}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {item?.rate}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {parseFloat(
                                              item.beforeTaxTotalAmount
                                            ).toFixed(2)}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {}
                                            {parseFloat(item.igst1).toFixed(2)}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {} {+item?.taxRate + "%"}
                                          </td>

                                          <td style={{ textAlign: "right" }}>
                                            {parseFloat(
                                              item.afterTaxTotalAmount
                                            ).toFixed(2)}{" "}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      {itemList?.map((item, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{item.hsnCode}</td>
                                          <td>{item?.itemName}</td>
                                          <td>
                                            {item?.quantity} {item?.unit}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {item?.rate}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {parseFloat(
                                              item.beforeTaxTotalAmount
                                            ).toFixed(2)}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {}
                                            {parseFloat(item.cgst1).toFixed(2)}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {} {+item?.taxRate / 2 + "%"}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {parseFloat(item.sgst1).toFixed(2)}{" "}
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {+item?.taxRate / 2 + "%"}
                                          </td>

                                          <td style={{ textAlign: "right" }}>
                                            {parseFloat(
                                              item.afterTaxTotalAmount
                                            ).toFixed(2)}{" "}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  )}
                                </table>
                                <div className="table_box">
                                  <div className="tableBox row m-0">
                                    <div className="terms-text col-6">
                                      {/*  <ul>
                                        <li> Terms &amp; Conditions </li>
                                        <li>
                                          {" "}
                                          1. Good received in good condition
                                        </li>
                                        <li>
                                          {" "}
                                          2. Good one sold will not be taken
                                          back
                                        </li>
                                        <li>
                                          {" "}
                                          3. We are not responsible for any loss
                                          or damage in transit.
                                        </li>
                                        <li>
                                          {" "}
                                          4. Good are dispatched entirely at the
                                          risk of customer
                                        </li>
                                        <li>
                                          {" "}
                                          5. Interest @ 24% will be charge if
                                          invoice amount not paid within 15 days
                                        </li>
                                        <li>
                                          {" "}
                                          6. All disputes are subject to indore
                                          ( M.P.) Jurisdiction E &amp; OE{" "}
                                        </li>
                                      </ul> */}
                                    </div>
                                    {parseFloat(data?.igst) !== 0 ? (
                                      <div className="pl-left col-6">
                                        <div className="terms-text">
                                          <ul>
                                            <li>
                                              {" "}
                                              <b>
                                                Total Amount Before Tax
                                              </b>{" "}
                                            </li>
                                            <li>
                                              {" "}
                                              <b>Add : IGST</b>
                                            </li>

                                            <li>
                                              {" "}
                                              <b>RF off &amp; Ad (+ / -)</b>
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          style={{ textAlign: "right" }}
                                          className="terms-text"
                                        >
                                          <ul>
                                            <li>
                                              {" "}
                                              <b>
                                                {parseFloat(
                                                  data?.total_before_tax
                                                ).toFixed(2)}
                                              </b>{" "}
                                            </li>

                                            <li>
                                              {" "}
                                              <b>
                                                {parseFloat(data?.igst).toFixed(
                                                  2
                                                )}
                                              </b>
                                            </li>
                                            <li>
                                              {" "}
                                              <b>
                                                {parseFloat(
                                                  data?.other
                                                ).toFixed(2)}
                                              </b>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="pl-left col-6">
                                        <div className="terms-text">
                                          <ul>
                                            <li>
                                              {" "}
                                              <b>
                                                Total Amount Before Tax
                                              </b>{" "}
                                            </li>
                                            <li>
                                              {" "}
                                              <b>Add : CGST</b>
                                            </li>
                                            <li>
                                              {" "}
                                              <b>Add : SGST</b>
                                            </li>

                                            <li>
                                              {" "}
                                              <b>RF off &amp; Ad (+ / -)</b>
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          style={{ textAlign: "right" }}
                                          className="terms-text"
                                        >
                                          <ul>
                                            <li>
                                              {" "}
                                              <b>
                                                {parseFloat(
                                                  data?.total_before_tax
                                                ).toFixed(2)}
                                              </b>{" "}
                                            </li>
                                            <li>
                                              {" "}
                                              <b>
                                                {parseFloat(data?.cgst).toFixed(
                                                  2
                                                )}
                                              </b>
                                            </li>
                                            <li>
                                              {" "}
                                              <b>
                                                {parseFloat(data?.sgst).toFixed(
                                                  2
                                                )}
                                              </b>
                                            </li>

                                            <li>
                                              {" "}
                                              <b>
                                                {parseFloat(
                                                  data?.other
                                                ).toFixed(2)}
                                              </b>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="tableBox row m-0">
                                    <div className="terms-text col-6"></div>
                                    <div className="pl-left col-6">
                                      <h4 className="bank-title pl-left">
                                        Grand Total
                                      </h4>
                                      <div
                                        className="terms-text"
                                        style={{ width: "60%" }}
                                      >
                                        <h4
                                          className="bank-title right-side"
                                          style={{ textAlign: "right" }}
                                        >
                                          {parseFloat(
                                            data?.totalAfterDiscountOrOtherCharges
                                          ).toFixed(2)}
                                          /-
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="tableBox row m-0">
                                    <div className="col-4">
                                      <div className="terms-text">
                                        <h4
                                          className="bank-title"
                                          style={{ fontSize: "14px" }}
                                        >
                                          :: Bank Details ::
                                        </h4>
                                        <div className="bank-detals-box">
                                          <div className="bank-label">
                                            <p>Bank Name</p>
                                            <p>Branch Name</p>
                                            <p>A/C No.</p>
                                            <p>IFSC Code</p>
                                          </div>
                                          <div className="bank-fill">
                                            <p>:Bank of India</p>
                                            <p>:Indore</p>
                                            <p>:</p>
                                            <p>:</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pl-left col-6 col-8">
                                      <div className="terms-text demo">
                                        <ul>
                                          <li>
                                            {" "}
                                            <b>For ISHWAR TRADERS</b>{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <b>Authorised Signature</b>{" "}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="button">
                <center>
                  <button
                    className="btn-convert"
                    style={{
                      backgroundColor: "#f44336",
                      border: "none",
                      color: "white",
                      padding: "15px 32px",
                      textAlign: "center",
                      textDecoration: "none",
                      display: "inline-block",
                      fontSize: "16px",
                      margin: "4px 2px",
                      cursor: "pointer",
                    }}
                    onClick={handlePrint}
                  >
                    Print Invoice
                  </button>
                </center>
                <br />
                <center>
                  {data?.irn != "undefined" ? (
                    <button
                      style={{
                        backgroundColor: "#f44336",
                        border: "none",
                        color: "white",
                        padding: "15px 32px",
                        textAlign: "center",
                        textDecoration: "none",
                        display: "inline-block",
                        fontSize: "16px",
                        margin: "4px 2px",
                        cursor: "pointer",
                      }}
                      className="btn-convert"
                      onClick={handleGoBack}
                    >
                      Go Back
                    </button>
                  ) : (
                    <button
                      style={{
                        backgroundColor: "#f44336",
                        border: "none",
                        color: "white",
                        padding: "15px 32px",
                        textAlign: "center",
                        textDecoration: "none",
                        display: "inline-block",
                        fontSize: "16px",
                        margin: "4px 2px",
                        cursor: "pointer",
                      }}
                      className="btn-convert"
                      onClick={generateEInvoice}
                    >
                      Genrate E-Invoice
                    </button>
                  )}
                </center>
              </div>
            </div>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default InvoicePrint;
