import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
const Navbar = () => {
  const { auth, setAuth } = useAuth();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setAuth({ token: null, user: null });
  };
  const userName = auth.user.c_name;
  const cheak = auth?.user?.role;
  console.log(cheak);
  return (
    <nav
      className=" navbar navbar-expand navbar-white navbar-light"
      style={{ backgroundColor: "#dddd" }}
    >
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/" className="nav-link">
            Home
          </Link>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/createInvoice" className="nav-link">
            Create E-Invoice
          </Link>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/invoiceList" className="nav-link">
            E-Invoice List
          </Link>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/partyList" className="nav-link">
            Party List
          </Link>
        </li>
        {cheak == "admin" ? (
          <>
            <li className="nav-item d-none d-sm-inline-block">
              <Link to="/subcription/user/list" className="nav-link">
                UserList
              </Link>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <Link to="/addClient" className="nav-link">
                Add Client
              </Link>
            </li>
          </>
        ) : (
          ""
        )}
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        <li
          className="nav-item"
          style={{
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 8,
            paddingBottom: 8,
          }}
        >
          <b>{userName}</b>
        </li>
        <li className="nav-item"></li>
        <li className="nav-item">
          <a className="nav-link" onClick={logout}>
            <i className="fas fa-power-off" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
