import React, { useEffect } from "react";
import { useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import Loader from "../components/loader";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "reactstrap-date-picker/lib/DatePicker";
import { RadioGroup, RadioButton } from 'react-radio-buttons'; 

const CreateInvoice = () => {
  const { auth } = useAuth();
  const [clientList, setClientList] = useState([]);
  const [clientData, setClientData] = useState({});
  const [clientgstin, setclientgstin] = useState("");
  const [clientpartyname, setClientPartyname] = useState("");
  const [clientfullAddress, setfullAddress] = useState("");
  const [clientpincode, setclientpincode] = useState("");
  const [loadinName, setLoadingName] = useState(auth?.user?.c_name || "");
  const [loadinAddress, setLoadingAddress] = useState(auth?.user?.fullAddress || "");
  const [loadinState, setLoadingState] = useState(auth?.user?.state || "");
  const [loadinPincode, setLoadingPincode] = useState(auth?.user?.pincode || "");
  const [loadinGSTIN, setLoadingGSTIN] = useState(auth?.user?.gstin || "");
  const [shippingName, setShippingName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingPincode, setShippingPincode] = useState("");
  const [shippingGSTIN, setShippingGSTIN] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceType, setinvoiceType] = useState("INV"||"");
  const [transportName, setTransportName] = useState("");
  const [vehNo, setVehNo] = useState("");
  const [LRNo, setLRNo] = useState("");
  const [eWayBillNo, setEwayBillNo] = useState("");
  const [eWayDate, setEwayDate] = useState("");
  const [brokerName, setBrokerName] = useState("");
  const [Distance, setDistance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [party, setparty] = useState("");
  const [selectedrcMode, setselectedrcMode] = useState("N");
  const handleRadioChangecheque = (event) => {
    console.log(event.target.value);
    setselectedrcMode(event.target.value);
  };  

  const navigate = useNavigate();
  console.log(clientpartyname);
console.log(invoiceDate);
  useEffect(() => {
    console.log(auth)
    const clientlist = async () => {
      try {
        const response = await axios.get("/client/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setClientList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    clientlist();
  }, []);

  const location = useLocation();
  const path = location?.state?.path;
  if (path == "EinvoiceProcess") {
    useEffect(() => {
      const uniqeID = location?.state?.uniqeID;
      const fetchInvoice = async () => {
        try {
          const response = await axios.post(
            "/invoice/fetch-invoice",
            {
              uniqeId: uniqeID,
            },
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            }
          );

          const partyname = response?.data?.invoice[0]?.partyname;
          for (var i = 0; i <= clientList.length; i++) {
            if (partyname == clientList[i]?.partyname) {
              setClientData(clientList[i]);
              setparty(clientList[i].partyname);
            }
          }

          setLoadingName(response?.data?.invoice[0]?.loadingName);
          setLoadingAddress(response?.data?.invoice[0]?.loadingAddress);
          setLoadingState(response?.data?.invoice[0]?.loadingState);
          setLoadingPincode(response?.data?.invoice[0]?.loadingPincode);
          setLoadingGSTIN(response?.data?.invoice[0]?.loadingGSTIN);
          setShippingName(response?.data?.invoice[0]?.shippingName);
          setShippingAddress(response?.data?.invoice[0]?.shippingAddress);
          setShippingState(response?.data?.invoice[0]?.shippingState);
          setShippingPincode(response?.data?.invoice[0]?.shippingPincode);
          setShippingGSTIN(response?.data?.invoice[0]?.shippingGSTIN);
          setInvoiceNumber(response?.data?.invoice[0]?.invoiceNumber);
          //Date Logic Start
          const date = response?.data?.invoice[0]?.invoiceDate;
          const invDate = new Date(date);
          setInvoiceDate(invDate);
          //Date Logic End
          setTransportName(response?.data?.invoice[0]?.transportName);
          setVehNo(response?.data?.invoice[0]?.vehNumber);
          setDistance(response?.data?.invoice[0]?.distance);
          setLRNo(response?.data?.invoice[0]?.lrNumber);
          setEwayBillNo(response?.data?.invoice[0]?.ewayBillNumber);
          setEwayDate(response?.data?.invoice[0]?.ewayBillDate);
          setBrokerName(response?.data?.invoice[0]?.agentName);
          setDiscount(response?.data?.invoice[0]?.discount);
          setCharges(response?.data?.invoice[0]?.other);

          console.log(response.data.itemList);
          const itemList = response.data.itemList;

          itemList?.map((item, index) => {
            const updatedField = {
              sr_no: index + 1,
              item_name: item?.itemName,
              item_narration: item?.item_narration,
              is_service: item?.is_service,
              hsn_code: item?.hsnCode,
              unit: item?.unit,
              item_quantity: item?.quantity,
              item_price: item?.rate,
              item_actual_amount: "",
              item_tax1_rate: item?.taxRate,
              item_tax1_amount: "",
              item_final_amount: "",
            };

            setFields((prevFields) => {
              const updatedFields = [...prevFields];
              updatedFields[index] = updatedField;
              return updatedFields;
            });
          });

          setInvoiceData(response.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchInvoice();
    }, [clientList]);
  }

  const submitForm = async () => {
    const response_party = await axios.post(
      "/client/edit",
      {
        partyname: clientpartyname,
        fullAddress: clientfullAddress,
        pincode: clientpincode,
        mobile: 9000000000,
        gstin: clientgstin,
      },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    if (
      // !loadinName ||
      // !loadinAddress ||
      // !loadinState ||
      // !loadinPincode ||
      // !loadinGSTIN ||
      // !shippingName ||
      // !shippingAddress ||
      // !shippingState ||
      // !shippingPincode ||
      // !shippingGSTIN ||
      !invoiceNumber ||
      !invoiceDate
      // !transportName ||
      // !vehNo ||
      // !LRNo ||
      // !eWayBillNo ||
      // !eWayDate ||
      // !brokerName
    ) {
      return toast.error("Please Fill All Fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (clientData?.partyname != null) {
    } else {
      return toast.error("Please Select Client", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    var count = 0;
    fields.map((item, index) => {
      const {
        item_name,
        item_narration,
        is_service,
        hsn_code,
        unit,
        item_quantity,
        item_price,
        item_tax1_rate,
      } = item;
      console.log(
        item_name,
        hsn_code,
        is_service,
        unit,
        item_quantity,
        item_price,
        item_tax1_rate
      );
      if (
        !item_name ||
        !hsn_code ||
        !unit ||
        !item_quantity ||
        !item_price
        // !item_tax1_rate
      ) {
        count++;
        return toast.error("Please Fill All Item Fields", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
    if (count == 0) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "/invoice/create",
          {
            loadingName: loadinName,
            loadingAddress: loadinAddress,
            loadingState: loadinState,
            loadingPincode: loadinPincode,
            loadingGSTIN: loadinGSTIN,
            shippingName: shippingName,
            shippingAddress: shippingAddress,
            shippingState: shippingState,
            shippingPincode: shippingPincode,
            shippingGSTIN: shippingGSTIN,
            invoiceNumber: invoiceNumber,
            invoiceDate: invoiceDate,
            transportName: transportName,
            vehNumber: vehNo,
            distance: Distance,
            lrNumber: LRNo,
            ewayBillNumber: eWayBillNo,
            ewayBillDate: eWayDate,
            invoiceType: invoiceType,
            rc:selectedrcMode,
            agentName: brokerName,
            partyname: clientpartyname,
            discount: discount,
            other: charges,
            data: fields,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (response.data.success || response_party.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/printInvoice", {
            state: {
              uniqeId: response.data.uniqid,
            },
          });
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  /**
   * Code For Update Invoice by Calling updateForm() function
   */

  const updateForm = async () => {
    if (
      // !loadinName ||
      // !loadinAddress ||
      // !loadinState ||
      // !loadinPincode ||
      // !loadinGSTIN ||
      // !shippingName ||
      // !shippingAddress ||
      // !shippingState ||
      // !shippingPincode ||
      // !shippingGSTIN ||
      !invoiceNumber ||
      !invoiceDate
      // !transportName ||
      // !vehNo ||
      // !LRNo ||
      // !eWayBillNo ||
      // !eWayDate ||
      // !brokerName
    ) {
      return toast.error("Please Fill All Fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (clientData?.partyname != null) {
    } else {
      return toast.error("Please Select Client", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    var count = 0;
    fields.map((item, index) => {
      const {
        item_name,
        item_narration,
        is_service,
        hsn_code,
        unit,
        item_quantity,
        item_price,
        item_tax1_rate,
      } = item;
      if (
        !item_name ||
        !hsn_code ||
        !unit ||
        !item_quantity ||
        !item_price
        // !item_tax1_rate
      ) {
        count++;
        return toast.error("Please Fill All Item Fields", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

    if (count == 0) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "/invoice/update",
          {
            uniqeId: location?.state?.uniqeID,
            loadingName: loadinName,
            loadingAddress: loadinAddress,
            loadingState: loadinState,
            loadingPincode: loadinPincode,
            loadingGSTIN: loadinGSTIN,
            shippingName: shippingName,
            shippingAddress: shippingAddress,
            shippingState: shippingState,
            shippingPincode: shippingPincode,
            shippingGSTIN: shippingGSTIN,
            invoiceNumber: invoiceNumber,
            invoiceDate: invoiceDate,
            transportName: transportName,
            vehNumber: vehNo,
            distance: Distance,
            lrNumber: LRNo,
            ewayBillNumber: eWayBillNo,
            ewayBillDate: eWayDate,
            invoiceType: invoiceType,
            rc:selectedrcMode,
            agentName: brokerName,
            partyname: clientData?.partyname,
            discount: discount,
            other: charges,
            data: fields,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/printInvoice", {
            state: {
              uniqeId: response.data.uniqid,
            },
          });
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  /**
   * End Update Invoice Logic
   */

  /**
   * Code For Create Input fields Dynamically
   */

  const [fields, setFields] = useState([
    {
      sr_no: 1,
      item_name: "",
      item_narration:"",
      is_service:"",
      hsn_code: "",
      unit: "BAG",
      item_quantity: "",
      item_price: "",
      item_actual_amount: "",
      item_tax1_rate: "",
      item_tax1_amount: "",
      item_final_amount: "",
    },
  ]);

  const addInputField = () => {
    const sr_no = fields.length + 1;
    const newField = {
      sr_no,
      item_name: "",
      item_narration:"",
      is_service:"",
      hsn_code: "",
      unit: "BAG",
      item_quantity: "",
      item_price: "",
      item_actual_amount: "",
      item_tax1_rate: "",
      item_tax1_amount: "",
      item_final_amount: "",
    };
    GrandTotal();
    setFields((prevFields) => [...prevFields, newField]);
  };

  const removeInputField = (index) => {
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };
  const [charges, setCharges] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);


  // useEffect(() => {
  //   GrandTotal();

  // });
const GrandTotal = () => {
  var total = 0;
  fields.map((item, index) => {
    total =
      total +
      item?.item_quantity * item?.item_price +
      item?.item_quantity * item?.item_price * (item?.item_tax1_rate / 100);
      console.log(total, item?.item_quantity, item?.item_price)
console.log((item?.item_tax1_rate / 100))
  });
  const roundoffvalue = parseFloat(
    total - discount + parseInt(charges)
  ).toFixed(2);
  setGrandTotal(roundoffvalue);
};
  const updateFieldValue = (index, field, value) => {
    GrandTotal();
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][field] = value;
      return updatedFields;
    });
  };

  /**End The Logic */

  return (
    <>
      <div className="card card-default">
        <div className="card-header">
          <h3 className="card-title">
            Use the form below to add E-Invoice to master
          </h3>
        </div>
        {/* /.card-header */}
        <form
          method="post"
          id="invoice_form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}
        >
          <div className="table-responsive" data-select2-id={53}>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Party Name</label>
                  <label className="float-right">
                    <Link to="/add_client">+Add</Link>
                  </label>
                  <select
                    className="form-control select2 select2-hidden-accessible"
                    name="partyname"
                    id="employee"
                    style={{ width: "100%" }}
                    data-select2-id="employee"
                    tabIndex={-1}
                    aria-hidden="true"
                    onChange={(e) => {
                      setClientData(clientList[e.target.value]);
                      setShippingGSTIN(clientList[e.target.value]?.gstin);
                      setclientgstin(clientList[e.target.value]?.gstin);
                      setClientPartyname(clientList[e.target.value]?.partyname);
                      setShippingName(clientList[e.target.value]?.partyname);
                      setfullAddress(clientList[e.target.value]?.fullAddress);
                      setShippingAddress(clientList[e.target.value]?.fullAddress);
                      setShippingState(clientList[e.target.value]?.state);
                      setclientpincode(clientList[e.target.value]?.pincode);
                      setShippingPincode(clientList[e.target.value]?.pincode);
                    }}
                  >
                    <option key="-1" value="-1">
                      -- Select Client --
                    </option>
                    {clientList?.map((item, index) => {
                      return (
                        <option value={index} key={index}>
                          {item.partyname}
                        </option>
                      );
                    })}
                  </select>
                  <span
                    className="select2 select2-container select2-container--default select2-container--below"
                    dir="ltr"
                    data-select2-id={1}
                    style={{ width: "100%" }}
                  >
                    <span className="dropdown-wrapper" aria-hidden="true" />
                  </span>
                </div>
                {clientData?.partyname ? (
                  <div className="row" style={{ display: "flex" }}>
                    <div className="col-md-12">
                      <span id="clGstin">
                        GSTIN:
                        <input
                          type="text"
                          name="loading_namee"
                          className="form-control"
                          id=""
                          placeholder="Enter Name"
                          value={clientgstin}
                          onChange={(e) => {
                            setclientgstin(e.target.value);
                          }}
                        />
                      </span>
                      <br />
                      <span id="partyname">
                        <input
                          type="text"
                          name="loading_namee"
                          className="form-control"
                          id=""
                          placeholder="Enter Name"
                          value={clientpartyname}
                          onChange={(e) => {
                            setClientPartyname(e.target.value);
                          }}
                        />
                      </span>
                      <br />
                      <span id="claddress">
                        <textarea
                          name="loading_namee"
                          className="form-control"
                          cols={60}
                          id=""
                          placeholder="Enter Name"
                          value={clientfullAddress}
                          onChange={(e) => {
                            setfullAddress(e.target.value);
                          }}
                        />
                      </span>
                      <br />
                      {/* <span id="claddress2">{clientData.address2}</span> */}
                      {/* <br /> */}
                      {/* <span id="clcity">{clientData.city}</span>&nbsp; */}
                      <span id="clpincode">
                        <input
                          type="text"
                          name="loading_namee"
                          className="form-control"
                          id=""
                          placeholder="Enter Name"
                          value={clientpincode}
                          onChange={(e) => {
                            setclientpincode(e.target.value);
                          }}
                        />
                      </span>
                      &nbsp;
                      {/* <span id="clstate">{clientData.state}</span> */}
                      {/* <br />
                      <span id="clphone">{clientData.mobile}</span>
                      <span id="clemail">{clientData.email}</span> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>Loading From</label>
                  <div className="form-group">
                    <input
                      type="text"
                      name="loading_namee"
                      className="form-control"
                      id=""
                      placeholder="Enter Name"
                      value={loadinName}
                      onChange={(e) => {
                        setLoadingName(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      name="loading_address"
                      className="form-control"
                      id=""
                      placeholder="Enter Address"
                      value={loadinAddress}
                      onChange={(e) => {
                        setLoadingAddress(e.target.value);
                      }}
                    />
                    <select
                      name="loading_state"
                      id="state"
                      className="form-control"
                      value={loadinState}
                      onChange={(e) => {
                        setLoadingState(e.target.value);
                      }}
                    >
                      <option value="Select">-Select-</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                      </option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Dadar and Nagar Haveli">
                        Dadar and Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>
                    <input
                      type="text"
                      name="loading_pincode"
                      className="form-control"
                      id=""
                      placeholder="Enter Pincode"
                      value={loadinPincode}
                      onChange={(e) => {
                        setLoadingPincode(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      name="loading_gstim"
                      className="form-control"
                      id=""
                      placeholder="Enter GSTIN"
                      value={loadinGSTIN}
                      onChange={(e) => {
                        setLoadingGSTIN(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>Shipped To</label>
                  <div className="form-group">
                    <input
                      type="text"
                      name="shipped_namee"
                      className="form-control"
                      id=""
                      placeholder="Enter Name"
                      value={shippingName}
                      onChange={(e) => {
                        setShippingName(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      name="shipped_address"
                      className="form-control"
                      id=""
                      placeholder="Enter Address"
                      value={shippingAddress}
                      onChange={(e) => {
                        setShippingAddress(e.target.value);
                      }}
                    />
                    <select
                      name="shipped_state"
                      id="state"
                      className="form-control"
                      value={shippingState}
                      onChange={(e) => {
                        setShippingState(e.target.value);
                      }}
                    >
                      <option value="Select">-Select-</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                      </option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Dadar and Nagar Haveli">
                        Dadar and Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>
                    <input
                      type="text"
                      name="shipped_pincode"
                      className="form-control"
                      id=""
                      placeholder="Enter Pincode"
                      value={shippingPincode}
                      onChange={(e) => {
                        setShippingPincode(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      name="shipped_gstim"
                      className="form-control"
                      id=""
                      placeholder="Enter  GSTIN"
                      value={shippingGSTIN}
                      onChange={(e) => {
                        setShippingGSTIN(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <label>
                  Invoice No. & Date
                  <span className="text-danger">
                    <big>*</big>
                  </span>
                </label>
                <input
                  type="text"
                  name="invoice_no"
                  id="invoice_no"
                  className="form-control input-sm"
                  placeholder="Enter Invoice No."
                  value={invoiceNumber}
                  onChange={(e) => {
                    setInvoiceNumber(e.target.value);
                  }}
                />
                {/* <DatePicker
                  selected={invoiceDate}
                  onChange={(date) => setInvoiceDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="  Select a date"
                /> */}
                <DatePicker
                  showClearButton={true}
                  id="to-date"
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e)}
                  placeholder="Select Date"
                  // maxDate={new Date().toISOString()}
                />
                  <select
                      name="document_type"
                      id="stadocument_typete"
                      className="form-control"
                      value={invoiceType}
                      onChange={(e) => {
                        setinvoiceType(e.target.value);
                      }}
                    >
                      <option value="Select">-Select Invoice Type-</option>
                      <option value="INV">Invoice</option>
                      <option value="DBN">Debit Note</option>
                      <option value="CRN">Credit Note</option>
                    </select>
                    <h6>
                    Reverce Charge
                </h6>
  <input type="radio" name="rc"  checked={selectedrcMode === "Y"} onChange={handleRadioChangecheque} value="Y" style={{padding: 10}}/><span style={{padding: 10}}>Yes</span>
  <input type="radio" name="rc" value="N"  checked={selectedrcMode === "N"} onChange={handleRadioChangecheque} style={{padding: 10}}/><span style={{padding: 10}}>No</span>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  name="trans_name"
                  id="trans_name"
                  className="form-control input-sm"
                  placeholder="Transporter Name"
                  value={transportName}
                  onChange={(e) => {
                    setTransportName(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="veh_no"
                  id="veh_no"
                  className="form-control input-sm"
                  placeholder="Veh No."
                  value={vehNo}
                  onChange={(e) => {
                    setVehNo(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="invoice_no"
                  id="invoice_no"
                  className="form-control input-sm"
                  placeholder="Enter Distance"
                  value={Distance}
                  onChange={(e) => {
                    setDistance(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="lr_no"
                  id="lr_no"
                  className="form-control input-sm"
                  placeholder="LR No."
                  value={LRNo}
                  onChange={(e) => {
                    setLRNo(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="eway_no"
                  id="eway_no"
                  className="form-control input-sm"
                  placeholder="E-Way Bill No."
                  value={eWayBillNo}
                  onChange={(e) => {
                    setEwayBillNo(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="eway_date"
                  id="eway_date"
                  className="form-control input-sm"
                  placeholder="E-Way Bill Date."
                  value={eWayDate}
                  onChange={(e) => {
                    setEwayDate(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="ba_name"
                  id="ba_name"
                  className="form-control input-sm"
                  placeholder="Broker / Agent Name"
                  value={brokerName}
                  onChange={(e) => {
                    setBrokerName(e.target.value);
                  }}
                />
              </div>
            </div>
            <br />
            <table id="invoice-item-table" className="table table-bordered">
              <tbody>
                <tr>
                  <th width="4%">Sr No.</th>
                  <th width="18%">Item Name</th>
                  <th width="9%">HSN Code</th>
                  <th width="9%">Unit</th>
                  <th width="9%">Quantity</th>
                  <th width="9%">Rate</th>
                  <th width="10%">Total Amt.</th>
                  <th width="18%" colSpan={2}>
                    Tax %
                  </th>
                  <th width="12%" rowSpan={2}>
                    Total
                  </th>
                  <th width="3%" rowSpan={2} />
                </tr>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th width="5%">Rate</th>
                  <th width="10%">Amt.</th>
                </tr>
                {fields.map((field, index) => (
                  <tr key={index}>
                    <td>
                      <span id="sr_no">{field.sr_no}</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_name[]"
                        id={`item_name${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm item_name"
                        placeholder="Item Name"
                        value={field.item_name}
                        onChange={(e) =>
                          updateFieldValue(index, "item_name", e.target.value)
                        }
                      />
                       <input
                        type="text"
                        name="item_narration[]"
                        id={`item_narration${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm item_name"
                        placeholder="Narration"
                        value={field.item_narration}
                        onChange={(e) =>
                          updateFieldValue(index, "item_narration", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="hsn_code[]"
                        id={`hsn_code${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm hsn_code"
                        placeholder="HSN Code"
                        value={field.hsn_code}
                        onChange={(e) =>
                          {updateFieldValue(index, "hsn_code", e.target.value); updateFieldValue(index, "is_service", e.target.value?.startsWith(99)?"Y":"N");}
                        }
                      />

{/* { //Check if message failed
        (field.hsn_code.startsWith(99) == true)
          ?  <input
          type="text"
          name="is_service[]"
          id={`is_service${field.sr_no}`}
          data-srno={field.sr_no}
          className="form-control input-sm is_service"
          placeholder="HSN Code"
          value={"Y"}
          onChange={(e) =>
            updateFieldValue(index, "is_service", e.target.value)
          }
        />
          : <input
          type="text"
          name="is_service[]"
          id={`is_service${field.sr_no}`}
          data-srno={field.sr_no}
          className="form-control input-sm is_service"
          placeholder="HSN Code"
          value={"N"}
          onChange={(e) =>
            updateFieldValue(index, "is_service", e.target.value)
          }
        />
      } */}

                       {/* <input
                        type="text"
                        name="is_service[]"
                        id={`is_service${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm is_service"
                        placeholder="HSN Code"
                        value={field.is_service}
                        onChange={(e) =>
                          updateFieldValue(index, "hsn_code", "N")
                        }
                      /> */}
                    </td>
                    <td>
                      <select
                        className="form-control select2 unit"
                        data-srno={field.sr_no}
                        name="unit[]"
                        id={`unit${field.sr_no}`}
                        style={{ width: "100%" }}
                        value={field.unit}
                        onChange={(e) =>
                          updateFieldValue(index, "unit", e.target.value)
                        }
                      >
                        <option value="BAG" data-select2-id={51}>
                          BAG
                        </option>
                        <option value="BAL">BAL</option>
                        <option value="BDL">BDL</option>
                        <option value="BKL">BKL</option>
                        <option value="BOU">BOU</option>
                        <option value="BOX">BOX</option>
                        <option value="BTL">BTL</option>
                        <option value="BUN">BUN</option>
                        <option value="CAN">CAN</option>
                        <option value="CBM">CBM</option>
                        <option value="CCM">CCM</option>
                        <option value="CMS">CMS</option>
                        <option value="CTN">CTN</option>
                        <option value="DOZ">DOZ</option>
                        <option value="DRM">DRM</option>
                        <option value="GGK">GGK</option>
                        <option value="GMS">GMS</option>
                        <option value="GRS">GRS</option>
                        <option value="GYD">GYD</option>
                        <option value="KGS">KGS</option>
                        <option value="KLR">KLR</option>
                        <option value="KME">KME</option>
                        <option value="LTR">LTR</option>
                        <option value="MLT">MLT</option>
                        <option value="MTR">MTR</option>
                        <option value="MTS">MTS</option>
                        <option value="NA">NA</option>
                        <option value="NOS">NOS</option>
                        <option value="OTH">OTH</option>
                        <option value="PAC">PAC</option>
                        <option value="PCS">PCS</option>
                        <option value="PRS">PRS</option>
                        <option value="QTL">QTL</option>
                        <option value="ROL">ROL</option>
                        <option value="SET">SET</option>
                        <option value="SQF">SQF</option>
                        <option value="SQM">SQM</option>
                        <option value="SQY">SQY</option>
                        <option value="TBS">TBS</option>
                        <option value="TGM">TGM</option>
                        <option value="THD">THD</option>
                        <option value="TON">TON</option>
                        <option value="TUB">TUB</option>
                        <option value="UGS">UGS</option>
                        <option value="UNT">UNT</option>
                        <option value="YDS">YDS</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_quantity[]"
                        id={`item_quantity${field.sr_no}`}
                        data-srno={field.sr_no}
                        placeholder="Quantity"
                        className="form-control input-sm item_quantity"
                        value={field.item_quantity}
                        onChange={(e) =>
                          updateFieldValue(
                            index,
                            "item_quantity",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_price[]"
                        id={`item_price${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm number_only item_price"
                        placeholder="Item Price"
                        value={field.item_price}
                        onChange={(e) =>
                          updateFieldValue(index, "item_price", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_actual_amount[]"
                        id={`item_actual_amount${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm item_actual_amount"
                        readOnly
                        value={parseFloat(
                          field.item_quantity * field.item_price
                        ).toFixed(2)}
                        // value={field.item_actual_amount}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_tax1_rate[]"
                        id={`item_tax1_rate${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm number_only item_tax1_rate"
                        value={field.item_tax1_rate}
                        placeholder="Tax %"
                        onKeyUp={GrandTotal}
                        onChange={(e) =>
                        {updateFieldValue(
                        index,
                        "item_tax1_rate",
                        e.target.value
                      );}
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_tax1_amount[]"
                        id={`item_tax1_amount${field.sr_no}`}
                        data-srno={field.sr_no}
                        readOnly
                        className="form-control input-sm item_tax1_amount"
                        // value={field.item_tax1_amount}
                        value={parseFloat(
                          field.item_quantity *
                            field.item_price *
                            (field.item_tax1_rate / 100)
                        ).toFixed(2)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_final_amount[]"
                        id={`item_final_amount${field.sr_no}`}
                        data-srno={field.sr_no}
                        readOnly
                        className="form-control input-sm item_final_amount"
                        // value={field.item_final_amount}
                        value={parseFloat(
                          field.item_quantity * field.item_price +
                            field.item_quantity *
                              field.item_price *
                              (field.item_tax1_rate / 100)
                        ).toFixed(2)}
                      />
                    </td>
                    {fields[index]?.sr_no == 1 ? (
                      <td>
                        <button
                          type="button"
                          name="add_row"
                          id="add_row"
                          className="btn btn-success btn-xs"
                          onClick={addInputField}
                        >
                          +
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                    {fields[index]?.sr_no != 1 ? (
                      <td>
                        <button
                          type="button"
                          name="add_row"
                          id="add_row"
                          className="btn btn-danger btn-xs remove_row"
                          onClick={() => removeInputField(index)}
                        >
                          X
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <center>
              {" "}
              <div className="col-md-3">
                Discount-:{0}
                <input
                  type="text"
                  // defaultValue={0}
                  value={discount}
                  onClick={GrandTotal}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  name="discount"
                  id="discount"
                  className="form-control input-sm discount"
                  placeholder="Discount"
                  required=""
                />
                <br />
                Other Charges-:
                <input
                  type="text"
                  name="othr"
                  id="othr"
                  className="form-control input-sm othr"
                  placeholder="Other Charges"
                  // defaultValue={0}
                  value={charges}
                  onClick={GrandTotal}
                  onChange={(e) => {
                    setCharges(e.target.value);
                  }}
                  required=""
                />
                <center>
                  <br />
                  <b>
                    Total Invoice Value :
                    <b>
                      <span id="final_total_amt">{"    " + grandTotal}</span>
                    </b>
                  </b>
                </center>
                <b>
                  <input
                    type="hidden"
                    name="total_item"
                    id="total_item"
                    className="form-control input-sm othr"
                    // defaultValue={1}
                    value={grandTotal}
                    readOnly
                  />
                </b>
              </div>
            </center>
          </div>
          <b>
            <center>
              <br />
              {isLoading ? (
                <Loader isLoading={isLoading} />
              ) : path == "EinvoiceProcess" ? (
                // update button
                <input
                  type="submit"
                  value="Update"
                  defaultValue="Create"
                  name="create_invoice"
                  onClick={() => {
                    updateForm();
                  }}
                  id="create_invoice"
                  style={{
                    backgroundColor: "#f44336",
                    border: "none",
                    color: "white",
                    padding: "15px 32px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: 16,
                    margin: "4px 2px",
                    cursor: "pointer",
                  }}
                  className="btn btn-default"
                />
              ) : (
                // create button
                <input
                  type="submit"
                  value="Create"
                  defaultValue="Create"
                  name="create_invoice"
                  onClick={() => {
                    submitForm();
                  }}
                  id="create_invoice"
                  style={{
                    backgroundColor: "#f44336",
                    border: "none",
                    color: "white",
                    padding: "15px 32px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: 16,
                    margin: "4px 2px",
                    cursor: "pointer",
                  }}
                  className="btn btn-default"
                />
              )}
            </center>
            <br />
          </b>
        </form>
        <StatusToast />
      </div>
    </>
  );
};

export default CreateInvoice;
